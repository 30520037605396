export enum DeviceType {
  // Cụm thu phát thanh ứng dụng CNTT-VT
  SPEAKER_SLAVER = 0,
  //  Bộ số hóa truyền thanh
  SPEAKER_MASTER = 1,
  // Bảng điện tử ứng dụng CNTT-VT
  DISPLAY,
  // Cụm thu FM
  SPEAKER_MASTER_FM,
  // Bảng LED
  DISPLAY_FM,
  // Bộ thu tích hợp tự động
  SPEAKER_SLAVER_AUTO,
  // Bộ thu-phát tích hợp tự động
  SPEAKER_MASTER_SLAVER_AUTO,
}

export const mapDeviceType = new Map<number, {label: string; value: DeviceType}>([
  [
    DeviceType.SPEAKER_SLAVER,
    {label: 'Cụm thu phát thanh ứng dụng CNTT-VT', value: DeviceType.SPEAKER_SLAVER},
  ],
  [DeviceType.SPEAKER_MASTER, {label: 'Bộ số hóa truyền thanh', value: DeviceType.SPEAKER_MASTER}],
  [DeviceType.DISPLAY, {label: 'Bảng điện tử ứng dụng CNTT-VT', value: DeviceType.DISPLAY}],
  [DeviceType.SPEAKER_MASTER_FM, {label: 'Cụm thu FM', value: DeviceType.SPEAKER_MASTER_FM}],
  [DeviceType.DISPLAY_FM, {label: 'Bảng LED', value: DeviceType.DISPLAY_FM}],
  [
    DeviceType.SPEAKER_SLAVER_AUTO,
    {label: 'Bộ thu tích hợp tự động', value: DeviceType.SPEAKER_SLAVER_AUTO},
  ],
  [
    DeviceType.SPEAKER_MASTER_SLAVER_AUTO,
    {label: 'Bộ thu-phát tích hợp tự động', value: DeviceType.SPEAKER_MASTER_SLAVER_AUTO},
  ],
])

export const getDeviceType = () => {
  return Object.values(DeviceType)
    .filter((item) => typeof item === 'number')
    .map((item: any) => mapDeviceType.get(item))
}
