import clsx from 'clsx'
import {useFormik} from 'formik'
import {FC, useEffect, useState} from 'react'
import Swal from 'sweetalert2'
import * as Yup from 'yup'
import {Loading} from '../../../../../_metronic/layout/components/loading/Loading'
import {useAuth} from '../../../../modules/auth/core/Auth'
import {getDistrictByCityId, getWardByDistrictId} from '../../../diachi/diachi-list/core/_requests'
import {useListView} from '../core/ListViewProvider'
import {useQueryResponse} from '../core/QueryResponseProvider'
//import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {City, District, maDinhDanh, Ward} from '../core/_models'
import {createMaDinhDanh, getProvinces, updateMaDinhDanh} from '../core/_requests'
import {Select} from 'antd'
import compareLabelMatch from '../../../../../untils/compare-label-match'

type Props = {
  isLoading: boolean
  madinhdanh: maDinhDanh
}

const editMaDinhDanhSchema = Yup.object().shape({
  Name: Yup.string()
    .trim()
    .min(3, 'Tối thiểu 3 ký tự')
    .max(250, 'Tối đa 250 ký tự')
    .required('Tên không được để trống'),
  NguonID: Yup.string()
    .required('mã không được để trống')
    .min(3, 'Tối thiểu 3 ký tự')
    .max(250, 'Tối đa 250 ký tự'),
})

const MaDinhDanhEditModalForm: FC<Props> = ({madinhdanh, isLoading}) => {
  const {auth} = useAuth()
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()

  const [lstCity, setLstCity] = useState<Array<City>>([])
  const [lstDistrict, setLstDistrict] = useState<Array<District>>([])
  const [lstWard, setLstWard] = useState<Array<Ward>>([])
  const [city, setCity] = useState<string>(madinhdanh.Address?.CityName || '')
  const [district, setDistrict] = useState<string>(madinhdanh.Address?.DistrictName || '')
  const [ward, setWard] = useState(madinhdanh.Address?.WardName || '')
  const [maDinhDanhForEdit] = useState<maDinhDanh>({
    Id: madinhdanh.Id,
    Name: madinhdanh.Name,
    NguonID: madinhdanh.NguonID,
    Address: madinhdanh.Address,
    Info: madinhdanh.Info,
  })

  useEffect(() => {
    getProvinces(auth?.configs).then((data) => {
      if (data.length > 0) {
        setLstCity(data)
        let cId = data[0].CityId
        if (madinhdanh.Address?.CityName) {
          let arr = data.filter((c: City) => {
            return c.CityName === city
          })
          if (arr.length > 0) cId = arr[0].CityId
        } else setCity(data[0].CityName || '')
        getDistrictByCityId(auth?.configs, cId || '').then((dataD) => {
          setLstDistrict(dataD)
          let dId = dataD[0].DistrictId
          if (madinhdanh.Address?.DistrictName) {
            let arr = dataD.filter(function (g: District) {
              return g.DistrictName === district
            })
            if (arr.length > 0) dId = arr[0].DistrictId
          } else {
            setDistrict(
              madinhdanh.Address?.DistrictName
                ? madinhdanh.Address?.DistrictName
                : dataD[0].DistrictName || ''
            )
          }
          getWardByDistrictId(auth?.configs, cId || '', dId || '').then((dataW) => {
            setLstWard(dataW)
            if (!madinhdanh.Address?.WardName && !madinhdanh.Address?.DistrictName)
              setWard(dataW[0].WardName || '')
          })
        })
      }
    })
  }, [])

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate('')
  }

  const onChangeCity = (value: string) => {
    setCity(lstCity.find((item) => item.CityId === value)?.CityName || '')
    getDistrictByCityId(
      auth?.configs,
      lstCity.find((item) => item.CityId === value)?.CityId || ''
    ).then((data) => {
      setLstDistrict(data)
      setDistrict(data[0].DistrictName || '')
      getWardByDistrictId(
        auth?.configs,
        lstCity.find((item) => item.CityId === value)?.CityId || '',
        data[0].DistrictId || ''
      ).then((dataW) => {
        setLstWard(dataW)
        setWard(dataW[0].WardName || '')
      })
    })
  }

  const getLstWard = (districtId: string) => {
    setDistrict(lstDistrict.find((item) => item.DistrictId === districtId)?.DistrictName || '')
    let cId
    let arr = lstCity.filter((c: City) => {
      return c.CityName === city
    })
    if (arr.length > 0) cId = arr[0].CityId
    getWardByDistrictId(
      auth?.configs,
      cId || '',
      lstDistrict.find((item) => item.DistrictId === districtId)?.DistrictId || ''
    ).then((data) => {
      setLstWard(data)
      setWard(data[0].WardName || '')
    })
  }

  const formik = useFormik({
    initialValues: maDinhDanhForEdit,
    validationSchema: editMaDinhDanhSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      values.Name = values.Name
      values.NguonID = values.NguonID
      values.Info = values.Info
      values.Address = {
        CityName: city,
        DistrictName: district,
        WardName: ward,
      }
      try {
        if (values.Id !== '0') {
          await updateMaDinhDanh(auth?.configs, values)
            .then(() => {
              Swal.fire({
                icon: 'success',
                title: 'Cập nhật thông tin thành công',
                text: ' ',
                showConfirmButton: false,
                timer: 1500,
              })
              setSubmitting(true)
              cancel(true)
            })
            .catch((error) => {
              Swal.fire({
                icon: 'error',
                title: error.response.data || 'Cập nhật thông tin thất bại',
                text: ' ',
                confirmButtonText: 'Đóng lại',
              })
            })
        } else {
          await createMaDinhDanh(auth?.configs, values)
            .then(() => {
              Swal.fire({
                icon: 'success',
                title: 'Thêm mã định danh thành công',
                text: ' ',
                showConfirmButton: false,
                timer: 1500,
              })
              setSubmitting(true)
              cancel(true)
            })
            .catch((error) => {
              Swal.fire({
                icon: 'error',
                title: error.response.data || 'Thêm mã định danh thất bại',
                text: ' ',
                confirmButtonText: 'Đóng lại',
              })
            })
        }
      } catch (ex) {
        console.error(ex)
      } finally {
      }
    },
  })

  return (
    <div className='nguoi-dung-body'>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}
          <div className='row' style={{alignItems: 'center'}}>
            <div className='col-12'>
              {/* begin::Label */}
              <label className='required fw-bold fs-6 mb-2'>Tên</label>
              <input
                placeholder='Tên'
                {...formik.getFieldProps('Name')}
                type='text'
                name='Name'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.Name && formik.errors.Name},
                  {
                    'is-valid': formik.touched.Name && !formik.errors.Name,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isLoading}
              />
              {formik.touched.Name && formik.errors.Name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='text-danger' role='alert'>
                      {formik.errors.Name}
                    </span>
                  </div>
                </div>
              )}
              {/* end::Label */}
            </div>
          </div>
          <div className='row mt-5' style={{alignItems: 'center'}}>
            <div className='col-12'>
              {/* begin::Label */}
              <label className='required fw-bold fs-6 mb-2'>Mã</label>
              <input
                placeholder='Mã'
                {...formik.getFieldProps('NguonID')}
                type='text'
                name='NguonID'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.NguonID && formik.errors.NguonID},
                  {
                    'is-valid': formik.touched.NguonID && !formik.errors.NguonID,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isLoading}
              />
              {formik.touched.NguonID && formik.errors.NguonID && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='text-danger' role='alert'>
                      {formik.errors.NguonID}
                    </span>
                  </div>
                </div>
              )}
              {/* end::Label */}
            </div>
          </div>

          <div className='row mt-5'>
            <div className='col-6'>
              {/* begin::Label */}
              <label className='fw-bold fs-6 mb-2'>Địa chỉ</label>
              <input
                placeholder='Địa chỉ'
                {...formik.getFieldProps('Info')}
                type='text'
                name='Info'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.Info && formik.errors.Info},
                  {
                    'is-valid': formik.touched.Info && !formik.errors.Info,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isLoading}
              />
              {/* end::Label */}
            </div>
            <div className='col-6'>
              {/* begin::Label */}
              <label className='fw-bold fs-6 mb-2'>Tỉnh</label>
              <Select
                className='form-select form-select-solid fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='two-step'
                data-hide-search='true'
                value={city}
                onChange={(value) => onChangeCity(value)}
                options={lstCity.map((item) => ({label: item.CityName, value: item.CityId}))}
                showSearch
                filterOption={compareLabelMatch}
              />

              {/* end::Label */}
            </div>
          </div>

          <div className='row mt-5'>
            <div className='col-6'>
              {/* begin::Input */}
              <label className='fw-bold fs-6 mb-2'>Huyện</label>
              <Select
                className='form-select form-select-solid fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='two-step'
                data-hide-search='true'
                {...formik.getFieldProps('DistrictName')}
                allowClear
                value={district}
                onChange={(value) => {
                  if (value) {
                    getLstWard(value)
                  } else {
                    setDistrict('')
                    setWard('')
                  }
                }}
                options={lstDistrict?.map((item) => ({
                  label: item.DistrictName,
                  value: item.DistrictId,
                }))}
                showSearch
                filterOption={compareLabelMatch}
              />
            </div>
            <div className='col-6'>
              {/* begin::Label */}
              <label className='fw-bold fs-6 mb-2'>Xã</label>
              <Select
                className='form-select form-select-solid fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='two-step'
                data-hide-search='true'
                {...formik.getFieldProps('WardName')}
                allowClear
                value={ward}
                onChange={(value) => {
                  if (value) {
                    const wardName = lstWard.find((item) => item.WardId === value)?.WardName || ''
                    setWard(wardName)
                  } else {
                    setWard('')
                  }
                }}
                showSearch
                options={lstWard.map((item) => ({
                  label: item.WardName,
                  value: item.WardId,
                }))}
                filterOption={compareLabelMatch}
              />
              {/* end::Label */}
            </div>
          </div>
          {/* end::Input group */}
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-5'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3 btn-cancel fw-b'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isLoading}
          >
            Hủy bỏ
          </button>
          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={
              isLoading ||
              formik.isSubmitting ||
              !formik.isValid ||
              !formik.touched ||
              !formik.dirty
            }
          >
            {madinhdanh.Id === '0' && <span className='indicator-label fw-b'>Thêm mới</span>}
            {madinhdanh.Id && madinhdanh.Id.length > 1 && (
              <span className='indicator-label fw-b'>Cập nhật</span>
            )}
            {(formik.isSubmitting || isLoading) && (
              <span className='indicator-progress'>
                Vui lòng đợi...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isLoading) && <Loading />}
    </div>
  )
}

export {MaDinhDanhEditModalForm}
