import axios from 'axios'
import {DynamicConfig, dynamicConfigUrl} from '../../../../configuration/config'
import {AuthModel, City, LocationArr, RefetchToken, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL
// const LOCATION_URL = process.env.REACT_APP_LOCATION_URL
const CITY_ID = process.env.REACT_APP_CITY_ID

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/users`
export const LOGIN_URL = `${API_URL}/users/login`
export const REGISTER_URL = `${API_URL}/users/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/users/forgot_password`
export const REFETCH_TOKEN = `${API_URL}/users/refresh-token`

// Server should return AuthModel
export function login(configs: DynamicConfig, UserName: string, Password: string) {
  // return axios.post<AuthModel>(LOGIN_URL, {
  return axios.post<AuthModel>(`${configs.apiUrl}/users/loginOTP`, {
    // return axios.post<AuthModel>(`${configs.apiUrl}/users/login`, {
    UserName,
    Password,
  })
}

export function LoginOTP(configs: DynamicConfig, TaiKhoan: string, CodeAuth: string) {
  return axios.post<AuthModel>(`${configs.apiUrl}/users/twoFactorAuthenticator`, {
    TaiKhoan,
    CodeAuth,
  })
}

export function LoginUSB(configs: DynamicConfig, UserName: string, ImeiUsbKey: string) {
  return axios.post<AuthModel>(`${configs.apiUrl}/users/twoFactorAuthenticatorByUsbKey`, {
    UserName,
    ImeiUsbKey,
  })
}

export function resendOTPCode(configs: DynamicConfig, userName: string) {
  return axios.get(`${configs.apiUrl}/users/get-ResendOTPCode`, {
    params: {userName},
  })
}

export function getGoogleAuthen(configs: DynamicConfig, userName: string) {
  return axios.get(`${configs.apiUrl}/users/tfaSetupGoogleAuthen`, {
    params: {userName},
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

export function dangXuat(configs: DynamicConfig) {
  return axios.get(`${configs.apiUrl}/users/Logout`).then((d: any) => d.data)
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}
export function refetchTokenService(
  configs: DynamicConfig,
  payload: {
    RefreshToken: string
    CaseLogin: string
    Info: string
  }
) {
  return axios.post<RefetchToken>(`${configs.apiUrl}/users/refresh-token`, payload)
}

export function getUserByToken(configs: DynamicConfig | undefined, token: string) {
  let headers_token = {
    Authorization: 'Bearer ' + token,
  }

  axios.defaults.headers.common = headers_token

  // var obj = axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL);
  var obj = axios.get<UserModel>(`${configs?.apiUrl}/users`)
  return obj
}

export function getLocationByCityId(configs: DynamicConfig) {
  var obj = axios.post<City>(`${configs.apiUrl}/location/get-locationByCityId`, {
    CityId: configs.cityId,
  })
  return obj
}

export function getAllPermissions(configs: DynamicConfig) {
  // return axios.get(`${API_URL}/users/get-allPermissions`)
  return axios.get(`${configs.apiUrl}/users/get-allPermissions`)
}

export function getConfigs() {
  return axios.get(dynamicConfigUrl)
}
