/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
//import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {useState} from 'react'
import {Link} from 'react-router-dom'
import * as Yup from 'yup'
import {defaultConfig} from '../../../../configuration/config'
import {useAuth} from '../core/Auth'
import {getConfigs, getUserByToken, login} from '../core/_requests'
import {Button, Checkbox} from 'antd'
import UsbConnect from './UsbConnect'
import {ChangePasswordModal} from './ChangePasswordModal'
import TwoFactorAuth from '../../../../components/TwoFactorAuth'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    // .email('Wrong email format')
    // .min(3, 'Minimum 3 symbols')
    // .max(50, 'Maximum 50 symbols')
    .required('Tên đăng nhập không được để trống'),
  password: Yup.string()
    // .min(3, 'Minimum 3 symbols')
    // .max(50, 'Maximum 50 symbols')
    .required('Mật khẩu không được để trống'),
})

const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser, setCityInfo, saveCity, configs} = useAuth()
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [isShowModal, setIsShowModal] = useState<boolean>(false)
  const [userName, setUserName] = useState<string>('')
  const [authData, setAuthData] = useState<any>()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      //TODO:: CHECK USB KEY
      // if (!localStorage.getItem('usbKey')) {
      //   setStatus('Vui lòng kết nối USB-KEY trước khi đăng nhập')
      //   return
      // }
      setLoading(true)
      try {
        const {data: auth} = await login(configs || defaultConfig, values.email, values.password)
        auth.api_token = auth.AccessToken
        auth.configs = configs
        setAuthData(auth)
        saveAuth(auth)
        setIsShowModal(auth?.IsChangePasswordFirst || false)
        setUserName(values.email)

        const {data: config} = await getConfigs()

        if (!auth?.IsChangePasswordFirst) {
          // ========Login OTP
          // auth.configs = configs
          saveAuth(auth)
          if (
            auth.TwoFactorAuthenticator === 1 ||
            (auth.TwoFactorAuthenticator === 3 && auth.IsAuthenGoogle)
          ) {
            document.getElementById('otp')?.click()
            return
          } else if (auth.UsbKeyLogin) {
            setStatus('Vui lòng kết nối với USBKEY để đăng nhập')
            localStorage.setItem('isUsbKey', 'true')
          } else if (auth.TwoFactorAuthenticator === 3 && !auth.IsAuthenGoogle) {
            document.getElementById('google')?.click()
          } else {
            const {data: user} = await getUserByToken(auth?.configs, auth?.AccessToken || '')
            user.api_token = auth.AccessToken
            setCurrentUser(user)
          }
          // ================

          // auth.api_token = auth.AccessToken
          // auth.configs = configs
          // saveAuth(auth)
          // const { data: locations } = await getLocation()
          // setLocations(locations)
          // {"id":2,"first_name":"Demo","last_name":"User","email":"admin@demo.com","email_verified_at":"2022-03-30T12:17:50.000000Z",
          // "created_at":"2022-03-30T12:17:50.000000Z","updated_at":"2022-05-31T12:01:59.000000Z","api_token":"sdfsdfsd34sg456trtgfxdg"}

          // const {data: user} = await getUserByToken(configs, auth.AccessToken)
          // user.api_token = auth.AccessToken

          // try {
          //   const { data: city } = await getLocationByCityId(config)
          //   saveCity(city)
          //   setCityInfo(city)
          // } catch (error) {
          //   console.log(error)
          // }

          // setCurrentUser(user)
        }
      } catch (error: any) {
        // console.error(error)
        saveAuth(undefined)
        setStatus(error.response.data || 'Tài khoản hoặc mật khẩu không đúng')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  const handleCancelPasswordFirst = () => {
    setIsShowModal(false)
  }

  const handleOKPasswordFirst = () => {
    // saveAuth(authData)
    setLoading(false)
  }

  return (
    <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_login_signin_form'
      >
        {/* begin::Heading */}
        <div className='text-center mb-10'>
          <h1 className='text-dark mb-3'>Đăng nhập tài khoản</h1>
          <h3 style={{color: '#1E2EC0', fontSize: '16px', lineHeight: '1.5'}}>
            Xin mời đăng nhập để trải nghiệm dịch vụ
          </h3>
          <div className='text-gray-400 fw-bold fs-4'>
            {/* <Link to='/auth/registration' className='link-primary fw-bolder'>
            Đăng ký tài khoản
          </Link> */}
          </div>
        </div>
        {/* begin::Heading */}

        {formik.status ? (
          <div className='alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        ) : (
          <div></div>
        )}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>Tên đăng nhập</label>
          <input
            placeholder='Tên đăng nhập'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
            type='email'
            name='email'
            autoComplete='off'
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <span className='text-danger' role='alert'>
                {formik.errors.email}
              </span>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row'>
          <div className='d-flex justify-content-between mt-n5'>
            <div className='d-flex flex-stack mb-2'>
              {/* begin::Label */}
              <label className='form-label fw-bolder text-dark fs-6 mb-0'>Mật khẩu</label>
              {/* end::Label */}
              {/* begin::Link */}
              {/* <Link
              to='/auth/forgot-password'
              className='link-primary fs-6 fw-bolder'
              style={{ marginLeft: '5px' }}
            >
              Quên mật khẩu ?
            </Link> */}
              {/* end::Link */}
            </div>
          </div>
          <div className='input-group'>
            <input
              type={showPassword ? 'text' : 'password'}
              placeholder='Mật khẩu'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            <div className='input-group-append'>
              <span
                className='input-group-text'
                style={{
                  padding: '17px',
                  borderRadius: '0 8px 8px 0',
                  border: 'none',
                }}
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <i className='fas fa-eye-slash' /> : <i className='fas fa-eye' />}
              </span>
            </div>
          </div>
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span className='text-danger' role='alert'>
                  {formik.errors.password}
                </span>
              </div>
            </div>
          )}
        </div>
        <div className='input-group mb-10 mt-1'>
          <Checkbox style={{color: '#8a9099', fontSize: 14, fontStyle: 'normal'}}>
            Tự động đăng nhập
          </Checkbox>
        </div>

        {/* end::Form group */}

        {/* begin::Action */}
        {/* <UsbConnect callback={funcSetIsConnect}></UsbConnect> */}
        {/* TODO:: CHECK USB KEY */}
        {/* <Link to='/auth/connect-usb' className='link-primary fw-bolder' style={{marginLeft: '5px'}}>
          Kết nối USB?
        </Link> */}
        <div className='text-center'>
          <Link id='otp' to='/auth/check-otp'></Link>
          <Link id='google' to='/auth/google-authen'></Link>
          <button
            type='submit'
            id='kt_sign_in_submit'
            className='btn btn-lg btn-primary w-100 mb-5'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className='indicator-label'>Đăng nhập</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Vui lòng đợi...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>

          {/* <TwoFactorAuth /> */}

          <Link
            to='/auth/forgot-password'
            className='link-primary fw-bolder'
            style={{marginLeft: '5px'}}
          >
            Quên mật khẩu?
          </Link>
        </div>
        {isShowModal && userName && (
          <ChangePasswordModal
            userName={userName}
            isModalOpen={isShowModal}
            handleCancel={handleCancelPasswordFirst}
            handleOK={handleOKPasswordFirst}
          />
        )}
        {/* <TwoFactorAuth></TwoFactorAuth> */}
        {/* end::Action */}
      </form>
    </div>
  )
}
