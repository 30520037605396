//import React, {useState} from 'react'
import axios, {AxiosResponse} from 'axios'
//import { Query } from 'react-query'
import {QueryState, Response} from '../../../../../_metronic/helpers'
import {DongBo, QueryResponse} from './_models'
import {DynamicConfig} from '../../../../../configuration/config'

const CAUHINHDONGBO = '/cauHinhDongBo'

const getCauHinhDongBos = (configs: DynamicConfig): Promise<QueryResponse> => {
  return axios
    .get(`${configs.apiUrl}${CAUHINHDONGBO}/get-cauHinhDongBos`, {
      headers: {
        currentPage: 1,
        pageSize: 999,
      },
    })
    .then((d: AxiosResponse<QueryResponse>) => d.data)
}

const getCauHinhDongBoFilter = (
  configs: DynamicConfig,
  state: QueryState
): Promise<QueryResponse> => {
  return axios
    .post(
      `${configs.apiUrl}${CAUHINHDONGBO}/get-cauHinhDongBoFilter`,
      state.filter || {
        FromTime: null,
        ToTime: null,
        Name: '',
        Info: '',
        Type: null,
        CreateByUser: '',
        Url: '',
      },
      {
        headers: {
          currentPage: state.currentPage,
          pageSize: state.pageSize,
        },
      }
    )
    .then((d: AxiosResponse<QueryResponse>) => d.data)
}

const getCauHinhDongBoById = (configs: DynamicConfig, id: string): Promise<DongBo | undefined> => {
  return axios
    .post(`${configs.apiUrl}${CAUHINHDONGBO}/get-cauHinhDongBoById`, {
      Id: id,
    })
    .then((d: AxiosResponse<DongBo>) => d.data)
}

const createCauHinhDongBo = (configs: DynamicConfig, data: DongBo): Promise<DongBo | undefined> => {
  return axios
    .post(`${configs.apiUrl}${CAUHINHDONGBO}/create-cauHinhDongBo`, {
      Name: data.Name,
      Info: data.Info,
      Url: data.Url,
      ApiKey: data.ApiKey,
      UserName: data.UserName,
      Password: data.Password,
      Type: Number(data.Type),
      NguonAddress: data.NguonAddress,
      PathApi: data.PathApi,
      NguonId: data.NguonId,
      ObjectSend: Number(data.ObjectSend),
    })
    .then((response: AxiosResponse<Response<DongBo>>) => response.data)
    .then((response: Response<DongBo>) => response.data)
}

const updateCauHinhDongBo = (configs: DynamicConfig, data: DongBo): Promise<DongBo | undefined> => {
  return axios
    .post(`${configs.apiUrl}${CAUHINHDONGBO}/update-cauHinhDongBo`, {
      Id: data.Id,
      Name: data.Name,
      Info: data.Info,
      Url: data.Url,
      ApiKey: data.ApiKey,
      UserName: data.UserName,
      Password: data.Password,
      Type: Number(data.Type),
      NguonAddress: data.NguonAddress,
      NguonId: data.NguonId,
      PathApi: data.PathApi,
      ObjectSend: Number(data.ObjectSend),
    })
    .then((response: AxiosResponse<Response<DongBo>>) => response.data)
    .then((response: Response<DongBo>) => response.data)
}

const Export = (configs: DynamicConfig, state: QueryState): Promise<Blob> => {
  return axios
    .post(
      `${configs.apiUrl}${CAUHINHDONGBO}/export-cauHinhDongBo`,
      state.filter || {
        FromTime: null,
        ToTime: null,
        Name: '',
        Info: '',
        Type: null,
        CreateByUser: '',
        Url: '',
      },
      {responseType: 'blob'}
    )
    .then((d: AxiosResponse<Blob>) => d.data)
}

const deleteCauHinhDongBo = (configs: DynamicConfig, id: string): Promise<void> => {
  return axios
    .put(`${configs.apiUrl}${CAUHINHDONGBO}/delete-cauHinhDongBo`, {Id: id})
    .then(() => {})
}

export {
  getCauHinhDongBos,
  getCauHinhDongBoFilter,
  Export,
  getCauHinhDongBoById,
  createCauHinhDongBo,
  updateCauHinhDongBo,
  deleteCauHinhDongBo,
}
