/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {useAuth} from '../../../../app/modules/auth/core/Auth'
import {Role} from '../../../../app/modules/auth/core/_models'
import {AsideMenuItem} from './AsideMenuItem'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'

export function AsideMenuMain() {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const VIEW_USER = currentUser?.Roles.find((item: Role) => {
    return item.Value === 3
  })
  const VIEW_LOG = currentUser?.Roles.find((item: Role) => {
    return item.Value === 6
  })
  const VIEW_USER_GROUP = currentUser?.Roles.find((item: Role) => {
    return item.Value === 7
  })
  const VIEW_DEVICE = currentUser?.Roles.find((item: Role) => {
    return item.Value === 10
  })
  const VIEW_DEVICE_GROUP = currentUser?.Roles.find((item: Role) => {
    return item.Value === 14
  })
  const VIEW_REPORT_DEVICE_STATUS = currentUser?.Roles.find((item: Role) => {
    return item.Value === 18
  })
  const VIEW_REPORT_RADIO = currentUser?.Roles.find((item: Role) => {
    return item.Value === 19
  })
  const VIEW_CONTENT_EDIT = currentUser?.Roles.find((item: Role) => {
    return item.Value === 20
  })
  const VIEW_NEWS = currentUser?.Roles.find((item: Role) => {
    return item.Value === 24
  })
  const VIEW_REPORT_COMMON = currentUser?.Roles.find((item: Role) => {
    return item.Value === 35
  })
  const VIEW_FEEDBACK = currentUser?.Roles.find((item: Role) => {
    return item.Value === 28
  })
  const VIEW_ADDRESS = currentUser?.Roles.find((item: Role) => {
    return item.Value === 30
  })
  const EDIT_MANUFACTURE = currentUser?.Roles.find((item: Role) => {
    return item.Value === 34
  })
  const VIEW_CATEGORY = currentUser?.Roles.find((item: Role) => {
    return item.Value === 37
  })
  const VIEW_ACCOUNT_INFO = currentUser?.Roles.find((item: Role) => {
    return item.Value === 2
  })
  const VIEW_NOTIFICATION = currentUser?.Roles.find((item: Role) => {
    return item.Value === 45
  })
  const VIEW_POWER = currentUser?.Roles.find((item: Role) => {
    return item.Value === 52
  })
  const VIEW_ROLE_GROUP = currentUser?.Roles.find((item: Role) => {
    return item.Value === 56
  })
  const VIEW_MA_DINH_DANH = currentUser?.Roles.find((item: Role) => {
    return item.Value === 63
  })
  const VIEW_CAU_HINH_DONG_BO = currentUser?.Roles.find((item: Role) => {
    return item.Value === 65
  })
  const VIEW_STREAM = currentUser?.Roles.find((item: Role) => {
    return item.Value === 70
  })
  const VIEW_STREAM_ADVANCED = currentUser?.Roles.find((item: Role) => {
    return item.Value === 79
  })
  const REPORT_SEND_VIEW = currentUser?.Roles.find((item: Role) => {
    return item.Value === 77
  })
  const ADMIN = currentUser?.Roles.find((item: Role) => {
    return item.Value === 999
  })
  return (
    <>
      <AsideMenuItem
        to='/tongquan'
        icon='/media/icons/icon/dashboard.svg'
        title='Tổng quan'
        fontIcon='bi-app-indicator'
      />

      {(VIEW_DEVICE || VIEW_CONTENT_EDIT || VIEW_NEWS || VIEW_POWER || ADMIN) && (
        <AsideMenuItemWithSub
          to='/ttcs'
          title='Quản lý hoạt động TTCS'
          icon='/media/icons/icon/management.svg'
          fontIcon='bi-person'
        >
          {(VIEW_DEVICE || ADMIN) && (
            // <AsideMenuItem
            //   to='/ttcs/daitruyenthanh'
            //   icon='/media/icons/icon/dot.svg'
            //   title='Giám sát trực tuyến'
            //   fontIcon='bi-layers'
            // />

            <AsideMenuItem
              to='/ttcs/giamsattructuyen'
              icon='/media/icons/icon/dot.svg'
              title='Giám sát trực tuyến'
              fontIcon='bi-layers'
            />
          )}

          {(VIEW_CONTENT_EDIT || ADMIN) && (
            <AsideMenuItem
              to='/ttcs/bientap'
              icon='/media/icons/icon/dot.svg'
              title='Biên tập'
              fontIcon='bi-layers'
            />
          )}

          {(VIEW_NEWS || ADMIN) && (
            <AsideMenuItem
              to='/ttcs/bantin'
              icon='/media/icons/icon/dot.svg'
              title='Quản lý bản tin'
              fontIcon='bi-layers'
            />
          )}

          {(VIEW_STREAM || VIEW_STREAM_ADVANCED || ADMIN) && (
            <AsideMenuItem
              to='/ttcs/phattructiep'
              icon='/media/icons/icon/dot.svg'
              title='Phát trực tiếp'
              fontIcon='bi-layers'
            />
          )}

          {(VIEW_DEVICE || ADMIN) && (
            <AsideMenuItem
              to='/ttcs/thietbi'
              icon='/media/icons/icon/dot.svg'
              title='Danh sách thiết bị'
              fontIcon='bi-layers'
            />
          )}
          {(VIEW_POWER || ADMIN) && (
            <AsideMenuItem
              to='/ttcs/nguonluc'
              icon='/media/icons/icon/dot.svg'
              title='Quản lý nguồn lực'
              fontIcon='bi-layers'
            />
          )}
        </AsideMenuItemWithSub>
      )}

      {(VIEW_REPORT_DEVICE_STATUS || VIEW_REPORT_RADIO || VIEW_REPORT_COMMON || ADMIN) && (
        <AsideMenuItemWithSub
          to='/baocao'
          title='Báo cáo thống kê'
          icon='/media/icons/icon/process.svg'
          fontIcon='bi-person'
        >
          {(VIEW_REPORT_COMMON || ADMIN) && (
            <AsideMenuItem
              to='baocao/baocaotonghop'
              icon='/media/icons/icon/dot.svg'
              title='Báo cáo tổng hợp'
              fontIcon='bi-layers'
            />
          )}

          {(VIEW_REPORT_DEVICE_STATUS || ADMIN) && (
            <AsideMenuItem
              to='baocao/baocaocumloa'
              icon='/media/icons/icon/dot.svg'
              title='Báo cáo hoạt động cụm loa'
              fontIcon='bi-layers'
            />
          )}

          {(VIEW_REPORT_RADIO || ADMIN) && (
            <AsideMenuItem
              to='baocao/baocaodaitt'
              icon='/media/icons/icon/dot.svg'
              title='Báo cáo hoạt động đài truyền thanh'
              fontIcon='bi-layers'
            />
          )}

          {(VIEW_REPORT_DEVICE_STATUS || ADMIN) && (
            <AsideMenuItem
              to='baocao/baocaobangdt'
              icon='/media/icons/icon/dot.svg'
              title='Báo cáo hoạt động bảng tin điện tử'
              fontIcon='bi-layers'
            />
          )}

          {(VIEW_REPORT_RADIO || ADMIN) && (
            <AsideMenuItem
              to='baocao/baocaophatthanh'
              icon='/media/icons/icon/dot.svg'
              title='Báo cáo phát thanh'
              fontIcon='bi-layers'
            />
          )}

          {(REPORT_SEND_VIEW || ADMIN) && (
            <AsideMenuItem
              to='baocao/guibaocao'
              icon='/media/icons/icon/dot.svg'
              title='Báo cáo đến HTTTN TW'
              fontIcon='bi-layers'
            />
          )}
        </AsideMenuItemWithSub>
      )}

      {(VIEW_FEEDBACK || ADMIN) && (
        <AsideMenuItem
          to='/phananh/danhsach'
          icon='/media/icons/icon/attention.svg'
          title='Phản ánh, kiến nghị'
          fontIcon='bi-app-indicator'
        />
      )}

      {(VIEW_USER ||
        VIEW_LOG ||
        VIEW_USER_GROUP ||
        VIEW_DEVICE_GROUP ||
        VIEW_ADDRESS ||
        EDIT_MANUFACTURE ||
        VIEW_CATEGORY ||
        ADMIN) && (
        <AsideMenuItemWithSub
          to='/quantrihethong'
          title='Quản trị hệ thống'
          icon='/media/icons/icon/system-update.svg'
          fontIcon='bi-person'
        >
          {(VIEW_USER_GROUP || ADMIN) && (
            <AsideMenuItem
              to='/quantrihethong/nhomnguoidung'
              icon='/media/icons/icon/dot.svg'
              title='Quản lý nhóm người dùng'
              fontIcon='bi-layers'
            />
          )}

          {(VIEW_USER || ADMIN) && (
            <AsideMenuItem
              to='/quantrihethong/taikhoan'
              icon='/media/icons/icon/dot.svg'
              title='Quản lý tài khoản'
              fontIcon='bi-layers'
            />
          )}

          {(VIEW_ADDRESS || VIEW_CATEGORY || ADMIN) && (
            <AsideMenuItemWithSub
              to='/quantrihethong/danhmuc'
              title='Danh mục'
              icon='/media/icons/icon/dot.svg'
              fontIcon='bi-layers'
            >
              {(VIEW_ADDRESS || ADMIN) && (
                <AsideMenuItem
                  to='/quantrihethong/danhmuc/diachi/danhsach'
                  title='Địa chỉ'
                  icon='/media/icons/icon/dot.svg'
                  fontIcon='bi-layers'
                />
              )}
              {(VIEW_CATEGORY || VIEW_MA_DINH_DANH || ADMIN) && (
                <>
                  {(VIEW_MA_DINH_DANH || ADMIN) && (
                    <AsideMenuItem
                      to='quantrihethong/danhmuc/madinhdanh/danhsach'
                      title='Mã định danh'
                      icon='/media/icons/icon/dot.svg'
                      fontIcon='bi-layers'
                    />
                  )}
                  {(VIEW_CATEGORY || ADMIN) && (
                    <>
                      <AsideMenuItem
                        to='quantrihethong/danhmuc/mucdouutien/danhsach'
                        title='Mức độ ưu tiên'
                        icon='/media/icons/icon/dot.svg'
                        fontIcon='bi-layers'
                      />
                      <AsideMenuItem
                        to='quantrihethong/danhmuc/linhvuc/danhsach'
                        title='Lĩnh vực bản tin'
                        icon='/media/icons/icon/dot.svg'
                        fontIcon='bi-layers'
                      />
                      <AsideMenuItem
                        to='quantrihethong/danhmuc/loaibantin/danhsach'
                        title='Loại bản tin'
                        icon='/media/icons/icon/dot.svg'
                        fontIcon='bi-layers'
                      />
                    </>
                  )}
                </>
              )}
            </AsideMenuItemWithSub>
          )}

          {(VIEW_LOG || ADMIN) && (
            <AsideMenuItem
              to='/quantrihethong/nhatky/danhsach'
              icon='/media/icons/icon/dot.svg'
              title='Nhật ký sử dụng'
              fontIcon='bi-layers'
            />
          )}

          {(VIEW_DEVICE_GROUP || ADMIN) && (
            <AsideMenuItem
              to='quantrihethong/nhomthietbi/danhsach'
              icon='/media/icons/icon/dot.svg'
              title='Quản lý đài truyền thanh'
              fontIcon='bi-layers'
            />
          )}

          {(EDIT_MANUFACTURE || ADMIN) && (
            <AsideMenuItem
              to='quantrihethong/nhacungcap/danhsach'
              icon='/media/icons/icon/dot.svg'
              title='Nhà cung cấp'
              fontIcon='bi-layers'
            />
          )}

          {(VIEW_ROLE_GROUP || ADMIN) && (
            <AsideMenuItem
              to='quantrihethong/nhomquyen/danhsach'
              icon='/media/icons/icon/dot.svg'
              title='Quản lý nhóm quyền'
              fontIcon='bi-layers'
            />
          )}

          {(VIEW_CAU_HINH_DONG_BO || ADMIN) && (
            <AsideMenuItemWithSub
              to='/quantrihethong/cauhinh'
              title='Cấu hình'
              icon='/media/icons/icon/dot.svg'
              fontIcon='bi-layers'
            >
              <AsideMenuItem
                to='/quantrihethong/cauhinh/cauhinhhethong'
                icon='/media/icons/icon/dot.svg'
                title='Cấu hình hệ thống'
                fontIcon='bi-layers'
              />
              <AsideMenuItem
                to='/quantrihethong/cauhinh/dongbodulieu'
                icon='/media/icons/icon/dot.svg'
                title='Đồng bộ dữ liệu'
                fontIcon='bi-layers'
              />
            </AsideMenuItemWithSub>
          )}
        </AsideMenuItemWithSub>
      )}

      <AsideMenuItem
        to='/huongdansudung/danhsach'
        icon='/media/icons/icon/document-fill-svgrepo-com.svg'
        title='Hướng dẫn sử dụng'
        fontIcon='bi-app-indicator'
      />
    </>
  )
}
