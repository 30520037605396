import {Modal} from 'antd'
import 'antd/dist/antd.css'
import {Suspense, useEffect, useState} from 'react'
import {Outlet, useNavigate} from 'react-router-dom'
import '../_metronic/assets/css/css_custome.css'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit, useAuth} from './modules/auth'
import UsbConnect from './modules/auth/components/UsbConnect'
import {getConfigs} from './modules/auth/core/_requests'
import {ExclamationCircleFilled} from '@ant-design/icons'

const {confirm} = Modal

const App = () => {
  const {auth, saveAuth, logout} = useAuth()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const navigate = useNavigate()

  // const [value, setValueState] = useState<string>(getGlobalValue())
  useEffect(() => {
    getConfigs().then((data) => {
      if (auth) {
        auth.configs = data.data
        saveAuth(auth)
      }
      let link: any = document.querySelector("link[rel~='icon']")
      if (window.location.host === 'htttn.truyenthanhso.com') {
        // if (window.location.host === 'localhost:3011') {
        link.href = ''
      } else {
        link.href = data.data.shortcutIcon
      }
    })
    setIsModalOpen(auth?.IsChangePasswordFirst || false)
  }, [])

  // TODO:: Sử dụng lấy biến toàn cục nếu cần
  // useEffect(() => {
  //   const handleChange = (newValue: string) => {
  //     setValueState(newValue)
  //   }
  //   onGlobalValueChange(handleChange)

  //   return () => {
  //     offGlobalValueChange(handleChange)
  //   }
  // }, [])

  const handleOk = () => {
    navigate('/caidat/doimatkhau')
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    logout()
    setIsModalOpen(false)
  }

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      {localStorage.getItem('isUsbKey') && (
        <div>
          <UsbConnect />
        </div>
      )}
      <Modal
        title='Thông báo'
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText='Đồng ý'
        cancelText='Hủy bỏ'
      >
        <p>Quý khách vui lòng đổi mật khẩu lần đầu trước khi thao tác!</p>
      </Modal>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <Outlet />
            <MasterInit />
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export {App}
